import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Sentry from 'libs/Sentry';

import { initLogger } from 'utils/logger';

import { RootState } from 'store';

const useAppInit = () => {
    const userData = useSelector((state: RootState) => state.user.user);

    useEffect(() => {
        if (userData) {
            Sentry.setUserData(userData);
        }
    }, [userData]);

    useEffect(() => {
        initLogger();
    }, []);
};

export { useAppInit };
