import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';

import { SnackbarProvider } from 'notistack';

import Router from 'routes/index';

import { GlobalMessageProvider } from 'providers/GlobalMessage';

import { useAppInit } from 'hooks/app/useAppInit';

import { logger } from 'utils/logger';
import { ERROR_REASONS } from 'utils/logger/transports/constants';

import { awsConfig } from './aws-export';
import ThemeCustomization from './themes';

try {
    Amplify.configure(awsConfig);
} catch (err) {
    logger.error(Error('Amplify configuration error'), {
        cause: err,
        tags: {
            reason: ERROR_REASONS.amplify,
        },
    });
}

const queryClient = new QueryClient();

const App = () => {
    useAppInit();

    return (
        <ThemeCustomization>
            <AmplifyProvider>
                <QueryClientProvider client={queryClient}>
                    <GlobalMessageProvider>
                        {/* @TODO need to refactor GlobalMessageProvider to SnackbarProvider only*/}
                        <SnackbarProvider maxSnack={5}>
                            <Router />
                        </SnackbarProvider>
                    </GlobalMessageProvider>

                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </AmplifyProvider>
        </ThemeCustomization>
    );
};

export default App;
