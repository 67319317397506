import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import { useGetUser } from 'hooks/api/user/useGetUser';
import useFormikErrors from 'hooks/formik/useFormikErrors';
import { RoleNames } from 'hooks/permission/types';

import { PermissionsFormikValuesType } from '../types';
import { getProjectByRole } from '../utils';

import { RootState } from 'store';

interface UseEnhancedModalProps {
    selectedUserId?: string;
    onClose: () => void;
    mutate: (values: PermissionsFormikValuesType) => void;
    deleteAllRightsHandler?: () => void;
}

const DEFAULT_PERMISSION = { role: '', projects: [] };

const useEnhancedModal = ({ selectedUserId, onClose, deleteAllRightsHandler, mutate }: UseEnhancedModalProps) => {
    const { showErrorMessages } = useFormikErrors();
    const projectList = useSelector((state: RootState) => state.generalData.projectsList);

    const { data: selectedUser, isLoading } = useGetUser({
        id: selectedUserId,
        onError: (err) =>
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error fetching user',
            }),
    });

    const currentRoles = useMemo(() => [...new Set(Object.values(selectedUser?.groups || {}))] as RoleNames[], [selectedUser?.groups]);

    const formik = useFormik<PermissionsFormikValuesType>({
        initialValues: {
            email: '',
            permission: [{ ...DEFAULT_PERMISSION }],
        },
        onSubmit: (values) => {
            if (values.permission?.length) {
                mutate(values);
            } else {
                deleteAllRightsHandler?.();
            }
            onClose();
        },
    });

    useEffect(() => {
        if (selectedUser) {
            formik.setFieldValue('email', selectedUser.email);
            currentRoles.forEach((role, index) => {
                formik.setFieldValue(`permission[${index}]`, {
                    role,
                    projects: getProjectByRole(selectedUser.groups, role),
                });
            });
        }

        return () => formik.resetForm();
    }, [selectedUser, currentRoles]);

    const getFilteredProjectList = useMemo(() => {
        const assignedProjects = formik.values.permission?.flatMap((item) => item.projects);
        return projectList?.filter((project) => !assignedProjects?.includes(project.system_name.toString()));
    }, [formik.values.permission, projectList]);

    const handleDeletePermission = (item: { role?: string; projects?: Array<string> }) => {
        formik.setFieldValue(
            'permission',
            formik.values.permission?.filter((permission) => permission !== item)
        );
    };

    const handleAddPermission = useCallback(() => {
        formik.setFieldValue('permission', [...formik.values.permission, { ...DEFAULT_PERMISSION }]);
    }, [formik]);

    const handleDeleteProject = useCallback(
        ({ index, value }: { index: number; value: string }) => {
            formik.setFieldValue(
                `permission[${index}].projects`,
                formik.values.permission?.[index]?.projects?.filter((item) => item !== value)
            );
        },
        [formik]
    );

    return {
        formik,
        getFilteredProjectList,
        isLoading,
        handleDeleteProject,
        handleDeletePermission,
        handleAddPermission,
        closeModal: onClose,
    };
};

export { useEnhancedModal };
