import React, { useCallback } from 'react';

import PermissionWrapper from 'components/PermissionWrapper';

import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

import { PermissionRow } from './components/PermissionRow';
import { useEnhancedModal } from './hooks/useEnhancedModal';
import { PermissionsFormikValuesType } from './types';

type Props = {
    open: boolean;
    selectedUserId?: string;
    onClose: () => void;
    mutate: (values: PermissionsFormikValuesType) => void;
    deleteAllRightsHandler?: () => void;
};

const DetailsPermissionsModal = ({ open, onClose, selectedUserId, mutate, deleteAllRightsHandler }: Props) => {
    const { formik, isLoading, getFilteredProjectList, handleAddPermission, handleDeletePermission, closeModal, handleDeleteProject } =
        useEnhancedModal({
            selectedUserId,
            onClose,
            mutate,
            deleteAllRightsHandler,
        });

    const renderPermissionRows = useCallback(() => {
        return (
            formik.values?.permission &&
            Object.values(formik.values.permission).map((item, index) => (
                <PermissionRow
                    key={index}
                    item={item}
                    index={index}
                    formik={formik}
                    getFilteredProjectList={getFilteredProjectList}
                    handleDeleteProject={handleDeleteProject}
                    handleDeletePermission={handleDeletePermission}
                />
            ))
        );
    }, [formik.values.permission, getFilteredProjectList, handleDeletePermission, handleDeleteProject]);

    return (
        <Dialog open={open} data-locator="editModal">
            <DialogTitle align="center" style={{ paddingTop: '20px' }}>
                <Typography variant="h5">User editing</Typography>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '20px', minWidth: 500 }}>
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={formik.handleSubmit} data-testid="detailsPermissionsForm">
                        <Box gap="24px" display="flex" flexDirection="column" marginBottom="24px">
                            <TextField
                                fullWidth
                                data-locator="emailInput"
                                id="email"
                                name="email"
                                label="Email*"
                                disabled
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
                                {renderPermissionRows()}
                                <PermissionWrapper type="edit" name="users">
                                    <Button onClick={handleAddPermission} data-locator="addPermissionButton">
                                        <Typography fontWeight="700">+ ADD PERMISSION</Typography>
                                    </Button>
                                </PermissionWrapper>
                            </Box>
                        </Box>
                        <Box display="flex" width="100%" alignItems="stretch" gap="8px">
                            <Button data-locator="cancelButton" style={{ flex: '1' }} size="large" variant="outlined" onClick={closeModal}>
                                CANCEL
                            </Button>
                            <PermissionWrapper type="create" name="users">
                                <Button
                                    data-locator="saveButton"
                                    style={{ flex: '1' }}
                                    size="large"
                                    variant="contained"
                                    disabled={!formik.touched}
                                    type="submit"
                                >
                                    SAVE
                                </Button>
                            </PermissionWrapper>
                        </Box>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export { DetailsPermissionsModal };
