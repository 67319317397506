import { Chip, Stack, useTheme } from '@mui/material';

import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'ui-elements/Logo';

import config from 'config';
import PropTypes from 'prop-types';

// ==============================|| DRAWER HEADER ||============================== //
const getEnvLabel = () => {
    const url = window.location.href;
    // only for local development
    if (url.includes('localhost')) {
        return 'LOCAL';
    }

    switch (config.ENV) {
        case 'prod':
            return 'PROD';
        case 'stage':
            return 'STAGE';
        case 'development':
            return 'DEV';
        case 'feature':
            return 'FEATURE';
        default:
            return 'UNKNOWN';
    }
};

const DrawerHeader = ({ open }) => {
    const theme = useTheme();

    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Logo />
                <Chip
                    color="success"
                    label={getEnvLabel()}
                    size="small"
                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
                    component="a"
                    clickable
                />
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool,
};

export default DrawerHeader;
