import { useCallback, useState } from 'react';

import { Box, TextField } from '@mui/material';

import { SearchActionButtons } from '../SearchActionButtons';

interface SearchFieldProps {
    onCancel: () => void;
    onApply: (value: string) => void;
    handleClose?: () => void;
    name: string;
    label: string;
    placeholder?: string;
    defaultValue?: string;
}

const SearchField = ({ defaultValue, label, name, placeholder, onCancel, onApply, handleClose }: SearchFieldProps) => {
    const [value, setValue] = useState(defaultValue || '');

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    const handleClear = useCallback(() => {
        setValue('');
    }, []);

    const handleCancel = useCallback(() => {
        handleClear();
        onCancel();
        handleClose && handleClose();
    }, [onCancel, handleClear, handleClose]);

    const handleApply = useCallback(() => {
        onApply(value);
        handleClose && handleClose();
    }, [value, onApply, handleClose]);

    return (
        <Box>
            <Box p={2}>
                <TextField
                    autoFocus
                    data-testid={`${name}_search`}
                    data-locator={`${name}_search`}
                    fullWidth
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                />
            </Box>
            <SearchActionButtons onApply={handleApply} onCancel={handleCancel} />
        </Box>
    );
};

export { SearchField };
