import { useEffect, useState } from 'react';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs, { Dayjs } from 'dayjs';

interface DataPickerProps {
    onChange: (date: string) => void;
    value: string;
    name: string;
}

const DatePicker = ({ onChange, value, name }: DataPickerProps) => {
    const [date, setDate] = useState<Dayjs | null>(dayjs(value));

    useEffect(() => {
        if (value === '') {
            setDate(null);
        }
    }, [value]);

    const handleChange = (newValue: Dayjs | null) => {
        setDate(newValue);

        if (newValue) {
            const formattedDate = newValue.format('YYYY-MM-DD');
            onChange(formattedDate);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                data-testid={`${name}_date_picker`}
                data-locator={`${name}_date_picker`}
                value={date}
                onChange={handleChange}
                disableFuture
                sx={{
                    maxWidth: '320px',
                }}
            />
        </LocalizationProvider>
    );
};

export { DatePicker };
