import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { useGlobalMessage } from 'providers/GlobalMessage';

import { useGetUsersList } from 'hooks/api/user/useGetUsersList';
import { usePutUser } from 'hooks/api/user/usePutUser';
import useFormikErrors from 'hooks/formik/useFormikErrors';
import { usePagination } from 'hooks/pagination/usePagination';
import { ProjectNames, RoleNames } from 'hooks/permission/types';
import usePermission from 'hooks/permission/usePermission';

import { prepareFilterPayload } from 'utils/common';
import { openLogsInNewTab } from 'utils/navigation/navigationHelpers';

import { FeaturesTypes } from 'pages/auditLogs/types';

import { AuditLogs } from 'constants/routerPath';

import { PermissionsFormikValuesType } from '../components/DetailsPermissionsModal/types';
import { UserType } from '../types';

import { RootState } from 'store';

const SCREEN_NAME = 'users_page';

const useEnhancedPage = () => {
    const toolbarValues = useSelector((state: RootState) => state.toolbar[SCREEN_NAME]);

    const { paginationModel, setPaginationParams } = usePagination();
    const [totalCount, setTotalCount] = useState(0);
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const [detailModal, setDetailModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [projectIdWithAdminRole, setProjectIdWithAdminRole] = useState<number | undefined>(undefined);
    const permissionRolePriority = useSelector((state: RootState) => state.generalData.permissionRolePriority);

    const queryClient = useQueryClient();
    const { pushGlobalMessage } = useGlobalMessage();
    const { showErrorMessages } = useFormikErrors();

    const projectList = useSelector((state: RootState) => state.generalData.projectsList);

    const { mutate, isLoading: isLoadingOnMutate } = usePutUser({
        onSuccess: () => {
            queryClient.invalidateQueries('users');

            pushGlobalMessage({
                message: 'User has been successfully updated',
                severity: 'success',
            });
        },
        onError: (err) => {
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error updating user',
            });
        },
    });

    const { data, isLoading } = useGetUsersList({
        searchParams: prepareFilterPayload(toolbarValues),
        onSuccess: (data) => {
            setTotalCount(data.total_count);
        },
        onError: (err) => {
            showErrorMessages({
                errors: err?.data,
                showGlobalMessage: true,
                customMessage: 'Error fetching users',
            });
        },
    });

    const { groups } = usePermission();

    const getProjectNameFromString = (value?: string) => {
        return value as unknown as ProjectNames;
    };

    useEffect(() => {
        const project = projectList?.find((item) => {
            return item?.system_name === getProjectNameFromString(permissionRolePriority.projectPriority);
        });

        project && setProjectIdWithAdminRole(project?.id);
    }, [groups]);

    const handleMutate = (values: PermissionsFormikValuesType) => {
        const roles: RoleNames[] = [];

        values.permission?.forEach((item) => {
            item.projects?.forEach((project) => roles.push(`${project}:${item.role}` as RoleNames));
        });
        mutate({ projectId: projectIdWithAdminRole, userId: selectedUser?.aws_id || '', body: { groups: roles } });
    };

    const handleOpenDetailLink = (e: React.MouseEvent, user: UserType) => {
        e.stopPropagation();

        user && setSelectedUser(user);
        setDetailModal(true);
    };

    const handleOpenDeleteModal = (user: UserType) => {
        user && setSelectedUser(user);
        setModalStatus(true);
    };

    const handleDeleteAllRights = (user: UserType) => {
        setModalStatus(false);
        user.aws_id && mutate({ projectId: projectIdWithAdminRole, userId: user.aws_id, body: { groups: [] } });
    };

    const handleLogsClick = (id: number) => {
        openLogsInNewTab(AuditLogs, FeaturesTypes.users, id);
    };

    const getProjectColor = (projectName: string) => projectList?.find((project) => projectName === project.system_name.toString())?.color;
    const getProjectName = (projectName: string) => projectList?.find?.((project) => project.system_name.toString() === projectName)?.name;

    const systemNamesList = projectList?.map((project) => project.system_name);

    const getCurrentUserProjectList = (user: UserType) => {
        return Object.keys(user.groups)?.map((item, index) => `${item}${index !== Object.keys(user.groups).length - 1 ? ', ' : ''}`);
    };

    return {
        toolbarValues,
        paginationModel,
        screenName: SCREEN_NAME,
        totalCount,
        selectedUser,
        detailModal,
        modalStatus,
        projectIdWithAdminRole,
        permissionRolePriority,
        projectList,
        data,
        isLoading,
        groups,
        systemNamesList,
        isLoadingOnMutate,
        setPaginationParams,
        getProjectNameFromString,
        setDetailModal,
        setModalStatus,
        handleMutate,
        handleOpenDetailLink,
        handleOpenDeleteModal,
        handleDeleteAllRights,
        handleLogsClick,
        getProjectColor,
        getProjectName,
        getCurrentUserProjectList,
    };
};

export { useEnhancedPage };
