import React, { ReactElement } from 'react';

import { Permission, PermissionType } from 'hooks/permission/types';

import PermissionWrapper from 'components/PermissionWrapper';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';

interface DeleteModalProps extends DialogProps {
    open: boolean;
    title: string;
    text?: string | ReactElement;
    onAccept: (e: React.MouseEvent) => void;
    onClose: () => void;
    acceptButtonTitle?: string;
    permissionName: Permission;
    projectId?: number;
    permissionType?: keyof PermissionType;
    isLoading?: boolean;
}

const DeleteModal = ({
    open,
    title,
    text,
    onAccept,
    onClose,
    acceptButtonTitle,
    projectId,
    permissionName,
    permissionType,
    isLoading,
    ...rest
}: DeleteModalProps) => {
    return (
        <Dialog open={open} {...rest}>
            <Box sx={{ py: 1, px: 2 }}>
                <DialogTitle variant="h5" component="h5" align="center">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography sx={{ mb: 3 }} align="center">
                            {text}
                        </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button disabled={isLoading} sx={{ flex: '1 1 0' }} variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                        <PermissionWrapper name={permissionName} type={permissionType || 'delete'} projectId={projectId}>
                            <LoadingButton loading={isLoading} sx={{ flex: '1 1 0' }} variant="contained" type="submit" onClick={onAccept}>
                                {acceptButtonTitle || 'Delete'}
                            </LoadingButton>
                        </PermissionWrapper>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default DeleteModal;
