import { SelectProjectsResponse } from 'hooks/api/general/useGetSelectProjects';
import { PRIORITY } from 'hooks/permission/constants';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';

interface PermissionRowProps {
    item: { role?: string; projects?: string[] };
    index: number;
    formik: any;
    getFilteredProjectList: SelectProjectsResponse[];
    handleDeleteProject: (args: { index: number; value: string }) => void;
    handleDeletePermission: (value: { role?: string; projects?: Array<string> }) => void;
}

const PermissionRow = ({
    item,
    index,
    formik,
    getFilteredProjectList,
    handleDeleteProject,
    handleDeletePermission,
}: PermissionRowProps) => {
    const isLast = index !== Object.values(formik.values.permission || []).length - 1;

    return (
        <>
            <Box display="flex" flex="1" width="100%" key={`${item.projects}_${index}`}>
                <Box width="100%" display="flex" flexDirection="column" gap="24px">
                    <FormControl>
                        <InputLabel id="select_project">Select project</InputLabel>
                        <Select
                            data-locator="projectDropdown"
                            labelId="select_project"
                            id="select_project"
                            multiple
                            value={item?.projects || []}
                            name={`permission[${index}].projects`}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label="Multiple Select" />}
                            placeholder="Select project"
                            renderValue={(selected) => (
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {selected?.map((value) => (
                                        <Chip
                                            key={value}
                                            label={value}
                                            style={{ borderRadius: '25px' }}
                                            onDelete={() => handleDeleteProject({ index, value })}
                                            deleteIcon={
                                                <CancelIcon
                                                    data-locator="deleteButton"
                                                    data-testid="CancelIcon"
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                        />
                                    ))}
                                </Stack>
                            )}
                        >
                            {getFilteredProjectList?.map((project) => (
                                <MenuItem data-locator="roleDropdown" key={project.id} value={project.system_name}>
                                    <Chip label={project.system_name} style={{ borderRadius: '25px' }} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        select
                        label="Select role"
                        onChange={formik.handleChange}
                        name={`permission[${index}].role`}
                        value={item?.role}
                    >
                        {PRIORITY.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <IconButton onClick={() => handleDeletePermission(item)}>
                    <DeleteForeverIcon data-testid="DeleteForeverIcon" />
                </IconButton>
            </Box>
            {isLast && (
                <hr
                    style={{
                        backgroundColor: '#000',
                        opacity: '0.5',
                        width: '100%',
                    }}
                />
            )}
        </>
    );
};

export { PermissionRow };
