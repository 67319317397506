import React from 'react';

import { PRIORITY } from 'hooks/permission/constants';

import DeleteModal from 'components/Modals/DeleteModal';
import PermissionWrapper from 'components/PermissionWrapper';
import { Toolbar } from 'components/Toolbar';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeIcon from '@mui/icons-material/Mode';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { DetailsPermissionsModal } from './components/DetailsPermissionsModal';
import { useEnhancedPage } from './hooks/useEnhancedPage';
import { UserType } from './types';

const PermissionsGrid = () => {
    const {
        toolbarValues,
        totalCount,
        data,
        detailModal,
        paginationModel,
        modalStatus,
        systemNamesList,
        screenName,
        isLoading,
        isLoadingOnMutate,
        selectedUser,
        setDetailModal,
        setModalStatus,
        setPaginationParams,
        getProjectColor,
        getCurrentUserProjectList,
        getProjectName,
        handleDeleteAllRights,
        handleLogsClick,
        handleMutate,
        handleOpenDeleteModal,
        handleOpenDetailLink,
        getProjectNameFromString,
    } = useEnhancedPage();

    const columns: GridColDef<UserType>[] = [
        {
            field: 'aws_id',
            headerName: 'ID',
            width: 60,
            sortable: false,
            renderCell: ({ row }) => (
                <Typography data-testid="internal-user-id" data-locator="internal-user-id" variant="body2">
                    {row?.id}
                </Typography>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 3,
            sortable: false,
            renderCell: ({ value }) => (
                <Typography data-testid="userEmail" data-locator="userEmail" variant="body2">
                    {value}
                </Typography>
            ),
        },
        {
            field: 'permission',
            headerName: 'Permission',
            flex: 3,
            sortable: false,
            renderCell: ({ row }) => {
                const userGroups = Object.keys(row.groups);

                return (
                    <Box
                        data-testid="userPermission"
                        data-locator="userPermission"
                        style={{ display: 'flex', flexDirection: 'column', padding: '20px 0', gap: '8px' }}
                    >
                        {userGroups.length ? (
                            userGroups.map?.((item: string) => {
                                const projectName = getProjectName(item.toString());

                                return (
                                    projectName && (
                                        <Chip
                                            key={item}
                                            label={`${projectName}: ${row.groups[getProjectNameFromString(item)]}`}
                                            variant="outlined"
                                            size="small"
                                            style={{
                                                borderRadius: '100px',
                                                backgroundColor: getProjectColor(item),
                                            }}
                                        />
                                    )
                                );
                            })
                        ) : (
                            <Typography variant="body2">No permission</Typography>
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: ({ row }) => {
                const userPermissions = Object?.keys(row?.groups);

                return (
                    <Box style={{ display: 'flex', gap: '8px' }}>
                        <PermissionWrapper type="edit" name="users">
                            <Tooltip title="Edit">
                                <IconButton
                                    data-testid="editButton"
                                    data-locator="editButton"
                                    onClick={(e) => handleOpenDetailLink(e, row)}
                                    color="primary"
                                >
                                    <ModeIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionWrapper>
                        <PermissionWrapper name="audit-log" type="get">
                            <Tooltip title="Logs">
                                <IconButton
                                    data-testid="showUsersLogsButton"
                                    data-locator="showUsersLogsButton"
                                    onClick={() => handleLogsClick(row.id as number)}
                                >
                                    <SettingsBackupRestore color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </PermissionWrapper>
                        {Boolean(userPermissions.length) && (
                            <PermissionWrapper type="delete" name="users">
                                <Tooltip title="Delete">
                                    <IconButton
                                        data-testid="deleteButton"
                                        data-locator="deleteButton"
                                        onClick={() => handleOpenDeleteModal(row)}
                                        color="error"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            </PermissionWrapper>
                        )}
                    </Box>
                );
            },
        },
    ];

    const getRowId = (row: UserType) => row?.aws_id || '';

    return (
        <>
            <DataGrid
                loading={isLoading || isLoadingOnMutate}
                disableColumnMenu
                rows={data ?? []}
                columns={columns}
                rowSpacingType="border"
                rowCount={totalCount}
                getRowId={getRowId}
                getRowHeight={() => 'auto'}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationParams}
                pageSizeOptions={[10, 25, 100]}
                sx={{
                    '& .MuiDataGrid-row': {
                        borderTop: 'solid 1px #e4e4e4',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: 200,
                    },
                }}
                slots={{ toolbar: Toolbar }}
                slotProps={{
                    toolbar: {
                        screenName,
                        toolbar: {
                            fields: [
                                [
                                    {
                                        type: 'search',
                                        variation: 'chip',
                                        label: 'Email',
                                        name: 'email',
                                        placeholder: 'Enter email',
                                        defaultValue: toolbarValues?.email || '',
                                    },
                                    {
                                        type: 'single-select',
                                        variation: 'chip',
                                        label: 'Project',
                                        name: 'project_name',
                                        placeholder: 'Choose project',
                                        options: systemNamesList,
                                        defaultValue: toolbarValues?.project_name || '',
                                    },
                                    {
                                        type: 'single-select',
                                        variation: 'chip',
                                        label: 'Role',
                                        name: 'group',
                                        placeholder: 'Choose role',
                                        options: PRIORITY,
                                        defaultValue: toolbarValues?.group || '',
                                    },
                                ],
                            ],
                        },
                        row: { ['data-locator']: 'userContainer' },
                    },
                }}
            />
            <DeleteModal
                title="Permission deleting"
                permissionName="users"
                text={
                    <>
                        <b>{selectedUser?.email}</b> will no longer have access to {selectedUser && getCurrentUserProjectList(selectedUser)}
                    </>
                }
                open={modalStatus}
                onClose={() => setModalStatus(false)}
                onAccept={() => !!selectedUser && handleDeleteAllRights(selectedUser)}
            />
            <DetailsPermissionsModal
                mutate={handleMutate}
                deleteAllRightsHandler={() => !!selectedUser && handleOpenDeleteModal(selectedUser)}
                open={detailModal}
                onClose={() => setDetailModal(false)}
                selectedUserId={selectedUser?.aws_id}
            />
        </>
    );
};

export default PermissionsGrid;
