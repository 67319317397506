import { SnackbarOrigin, useSnackbar } from 'notistack';

import { ERROR_DATA } from 'hooks/api/type';

interface SetErrorProps<T> {
    setError?: (field: string, value: string | undefined) => void;
    errors: ERROR_DATA<T>['data'];
    showGlobalMessage?: boolean;
    customMessage?: string;
    defaultErrorMessage?: string;
    anchorOrigin?: SnackbarOrigin;
}

const DEFAULT_MESSAGE = 'Error in:';
const DEFAULT_ERROR_MESSAGE = 'Unknown error.';

/**
 * useFormikErrors hooks to manage error handling and show error messages
 * @returns {Function} showErrorMessages - Function to show error messages in the global message and set errors in the form
 */
const useFormikErrors = <T>() => {
    const { enqueueSnackbar } = useSnackbar();

    const showErrorMessages = ({
        setError,
        errors,
        showGlobalMessage = false,
        customMessage = DEFAULT_MESSAGE,
        defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right',
        },
    }: SetErrorProps<T>) => {
        const errorFields: string[] = [];
        let errorsLength = 0;

        if (!errors) {
            enqueueSnackbar(defaultErrorMessage, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin,
            });
            return;
        }

        if ('errors' in errors && errors.errors) {
            const entries = Object.entries(errors.errors);
            errorsLength = entries.length;

            entries.forEach(([field, message]) => {
                setError && setError(field, message as string);
                errorFields.push(field);
            });

            if (showGlobalMessage) {
                enqueueSnackbar(`${customMessage} - Error count: ${errorsLength}. Fields with error: ${errorFields.join(', ')}`, {
                    variant: 'error',
                    preventDuplicate: false,
                    autoHideDuration: 6000,
                    anchorOrigin,
                });
            }
        } else if ('error' in errors) {
            enqueueSnackbar(`${customMessage} - Error: ${errors.error}`, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 6000,
                anchorOrigin,
            });
        } else if ('message' in errors && errors.message) {
            errorsLength = 1;
            enqueueSnackbar(`${errors.message} - Error count: ${errorsLength}.`, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin,
            });
        } else {
            enqueueSnackbar(defaultErrorMessage, {
                variant: 'error',
                preventDuplicate: false,
                autoHideDuration: 5000,
                anchorOrigin,
            });
        }

        return {
            errorsLength,
            errorFields,
        };
    };

    return {
        showErrorMessages,
    };
};

export default useFormikErrors;
