import { Permission, PermissionItem, PermissionType, ProjectNames } from '../types';

import { store } from 'store';

const isPermissionTypesAllowed = (permissionList?: Partial<PermissionItem>, name?: Permission, type?: keyof PermissionType) => {
    if (permissionList && name && name in permissionList) {
        const permissionTypesList = permissionList[name];

        if (permissionTypesList) {
            return type ? !!permissionTypesList[type] : false;
        }
    }
    return false;
};

const projectNameCache = new Map<number, ProjectNames>();

const getProjectNameHeader = (projectId?: number): Record<string, string> => {
    if (projectId && projectNameCache.has(projectId)) {
        return { project_name: projectNameCache.get(projectId) as ProjectNames };
    }

    const state = store.getState();
    const projectsList = state.generalData.projectsList;

    const projectName = projectId ? projectsList.find((project) => project.id === projectId)?.system_name : undefined;

    if (projectName && projectId) {
        projectNameCache.set(projectId, projectName as ProjectNames);
        return { project_name: projectName as ProjectNames };
    }

    return {};
};

export { isPermissionTypesAllowed, getProjectNameHeader, projectNameCache };
