import React from 'react';

const LockIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="LockIcon">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.8125 26.6316H18.4207C15.4778 27.3224 13.2695 29.9775 13.2695 33.1234V66.4567C13.2695 70.1234 16.2695 73.1234 19.9362 73.1234H39.8125V56.4556C36.2023 56.389 33.2695 53.4154 33.2695 49.79C33.2695 46.1647 36.2023 43.191 39.8125 43.1245V26.6316Z"
                fill="url(#paint0_linear_4474_7764)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.8105 26.6325H59.9364C63.6031 26.6325 66.6031 29.6325 66.6031 33.2992V66.6325C66.6031 69.7783 64.3948 72.4335 61.4518 73.1243H39.8105V56.6313C39.8524 56.6321 39.8944 56.6325 39.9364 56.6325C43.6031 56.6325 46.6031 53.6325 46.6031 49.9658C46.6031 46.2992 43.6031 43.2992 39.9364 43.2992C39.8944 43.2992 39.8524 43.2996 39.8105 43.3003V26.6325Z"
                fill="url(#paint1_linear_4474_7764)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.5416 26.555H59.8749C60.061 26.555 60.2453 26.5627 60.4276 26.5779H19.3223C19.5046 26.5627 19.6889 26.555 19.8749 26.555H23.2083V19.8883C23.2083 10.6883 30.6749 3.22168 39.8749 3.22168C49.0749 3.22168 56.5416 10.6883 56.5416 19.8883V26.555ZM29.5416 26.555H50.2083V19.8883C50.2083 14.1883 45.5749 9.55501 39.8749 9.55501C34.1749 9.55501 29.5416 14.1883 29.5416 19.8883V26.555Z"
                fill="url(#paint2_linear_4474_7764)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_4474_7764"
                    x1="13.2695"
                    y1="26.6316"
                    x2="37.9978"
                    y2="72.003"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="0.240796" stopColor="#313D81" />
                    <stop offset="0.491851" stopColor="#6280FF" />
                    <stop offset="0.975" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_4474_7764"
                    x1="39.8105"
                    y1="73.1243"
                    x2="64.4129"
                    y2="27.5593"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="0.240796" stopColor="#313D81" />
                    <stop offset="0.491851" stopColor="#6280FF" />
                    <stop offset="0.975" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_4474_7764"
                    x1="19.3223"
                    y1="3.22168"
                    x2="24.3908"
                    y2="31.8896"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="0.240796" stopColor="#313D81" />
                    <stop offset="0.491851" stopColor="#6280FF" />
                    <stop offset="0.975" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LockIcon;
